<template>
    <div v-if="isLoading" class="l-stack l-gap-3 l-padded">
        <VSpinner size="medium" line-fg-color="#000" :speed="1" />
    </div>

    <div v-else>
        <div class="l-new-asset-view l-stack l-gap-3 l-padded">
            <FormInput
                v-model="name"
                :label="$t('name')"
                :required="true"
                :show-required-error="$v.name.$dirty && !this.$v.name.required"
                @blur="$v.name.$touch()"
            />

            <FormInput
                v-model="description"
                :label="$t('description')"
                input-tag="textarea"
            />

            <FormInput
                v-if="shouldShowToleranceField"
                v-model="$v.tolerance.$model"
                :label="$t('tolerance')"
                :errors="toleranceErrors"
                :info="$t('toleranceInfo')"
            />

            <InfoBox v-if="!polygonDrawn">
                {{ $t('drawLocation') }}
            </InfoBox>

            <InfoBox v-if="polygonDrawn && isDrawingLocationPolygon">
                {{ $t('endLocation') }}
            </InfoBox>

            <div v-if="polygonDrawn" class="l-stack l-gap-2">
                <AxButton
                    color="secondary"
                    @click="confirmResetLocationPolygon"
                >
                    {{ $t('resetLocationPolygon') }}
                </AxButton>
            </div>

            <div v-if="isAdminOrStaff" class="l-stack l-gap-2">
                <h3 class="t-title">{{ $t('rights') }}</h3>
                <div>
                    <div class="form-label">
                        {{ $t('usersViewingAccess') }}
                    </div>
                    <UserSelect
                        v-model="usersViewingAccess"
                        :exclude="
                            location
                                ? [location.owner, ...usersEditingAccess]
                                : [...usersEditingAccess]
                        "
                        :multiple="true"
                    />
                </div>
                <div>
                    <div class="form-label">
                        {{ $t('usersEditingAccess') }}
                    </div>
                    <UserSelect
                        v-model="usersEditingAccess"
                        :exclude="
                            location
                                ? [location.owner, ...usersViewingAccess]
                                : [...usersViewingAccess]
                        "
                        :multiple="true"
                    />
                </div>
                <VDialog />
            </div>
            <div v-if="polygonDrawn">
                <FormNavigation @save="save" />
            </div>
        </div>
        <VDialog />
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { integer, minValue, required } from 'vuelidate/lib/validators'
import VSpinner from 'vue-simple-spinner'

import AxButton from './AxButton'
import FormInput from './FormInput'
import FormNavigation from './FormNavigation'
import InfoBox from './InfoBox'
import UserSelect from './UserSelect'

export default {
    name: 'LocationForm',
    components: {
        AxButton,
        FormInput,
        FormNavigation,
        InfoBox,
        UserSelect,
        VSpinner,
    },
    props: {
        id: {
            type: [Number, String],
            default: null,
            required: false,
        },
    },
    data() {
        return {
            name: '',
            description: '',
            tolerance: null,
            polygon: [],
            isLoading: false,
            polygonSaved: false,
            usersViewingAccess: null,
            usersEditingAccess: null,
            location: null,
        }
    },
    computed: {
        ...mapState('createLocation', [
            'isDrawingLocationPolygon',
            'locationPolygon',
        ]),
        ...mapState('location', ['locations']),
        ...mapGetters('auth', ['isAdminOrStaff']),
        polygonDrawn() {
            return this.polygon.geometry?.coordinates[0].length > 3
        },
        shouldShowToleranceField() {
            return process.env.VUE_APP_ENVIRONMENT_NAME !== 'orkanet'
        },
        toleranceErrors() {
            if (!this.$v.tolerance.$dirty) {
                return []
            }

            const errors = []

            if (!this.$v.tolerance.integer) {
                errors.push(this.$t('notInteger'))
            }

            if (!this.$v.tolerance.minValue) {
                errors.push(this.$t('notPositive'))
            }

            return errors
        },
    },
    watch: {
        locationPolygon(state) {
            this.polygon = state
        },
    },
    validations: {
        name: {
            required,
        },
        tolerance: {
            integer,
            minValue: minValue(0),
        },
    },
    mounted() {
        if (this.id) {
            this.location = this.locations.find(item => item.id == this.id)
        }
    },
    methods: {
        ...mapMutations('createLocation', [
            'setLocationPolygon',
            'setLocationPolygonInitial',
            'setResettingLocationPolygon',
        ]),
        save() {
            if (this.isValid()) {
                this.$emit('save', this.getValues())
            }
        },
        saved(locationId) {
            this.polygonSaved = true
            this.$router.push({ name: 'location', params: { id: locationId } })
        },
        getValues() {
            return {
                name: this.name,
                description: this.description,
                geofence_tolerance: this.tolerance ? +this.tolerance : null,
                geofence: this.polygon.geometry,
            }
        },
        getPermissions() {
            return {
                usersViewingAccess: this.usersViewingAccess,
                usersEditingAccess: this.usersEditingAccess,
            }
        },
        setLoading(loading) {
            this.isLoading = loading
        },
        setValues(values) {
            this.name = values.name
            this.description = values.description
            this.tolerance = values.geofence_tolerance
            this.setLocationPolygonInitial(null)
            this.setLocationPolygonInitial(values.geofence)
            this.usersViewingAccess = values.usersViewingAccess
            this.usersEditingAccess = values.usersEditingAccess
        },
        isValid() {
            this.$v.$touch()
            return !this.$v.$invalid
        },
        confirmResetLocationPolygon() {
            this.confirmLeave(
                this.$t('confirmResetLocationPolygon'),
                confirmed => {
                    if (confirmed) {
                        this.setResettingLocationPolygon(true)
                    }
                }
            )
        },
        confirmLeave(title, confirmHandler) {
            this.$modal.show('dialog', {
                title: title,
                buttons: [
                    {
                        title: this.$t('confirmOk'),
                        handler: () => {
                            confirmHandler(true)
                            this.$modal.hide('dialog')
                        },
                    },
                    {
                        title: this.$t('shared.cancel'),
                        handler: () => {
                            confirmHandler(false)
                            this.$modal.hide('dialog')
                        },
                    },
                ],
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "name": "Name",
        "description": "Description",
        "drawLocation": "Draw the desired area on the map to create a new location.",
        "confirmResetLocationPolygon": "Really discard drawing?",
        "confirmOk": "Discard",
        "resetLocationPolygon": "Discard drawing",
        "tolerance": "Geofence Tolerance (meters)",
        "toleranceInfo": "If you use tracking devices, you can set here how far the device can be out of the location. Use rules to be informed about leaving the location.",
        "notInteger": "Must be an integer",
        "notPositive": "Must be positive",
        "endLocation": "To finish drawing, click on the last corner again. After that, you can still edit the location.",
        "usersViewingAccess": "Users with viewing access",
        "usersEditingAccess": "Users with editing access",
        "rights": "Rights"
    },
    "de": {
        "name": "Name",
        "description": "Beschreibung",
        "drawLocation": "Zeichnen Sie auf der Karte die gewünschte Fläche um einen neuen Standort zu erstellen.",
        "confirmResetLocationPolygon": "Zeichnung wirklich verwerfen?",
        "confirmOk": "Verwerfen",
        "resetLocationPolygon": "Zeichnung verwerfen",
        "tolerance": "Geofence Toleranz (Meter)",
        "toleranceInfo": "Wenn sie tracking Geräte benutzen, können Sie hier einstellen wie weit sich das Gerät ausserhalb des Standortes befinden darf. Nutzen Sie Regeln um über das Verlassen des Standortes informiert zu werden.",
        "notInteger": "Muss eine Nummer sein",
        "notPositive": "Muss positiv sein",
        "endLocation": "Um das Zeichnen zu beenden, können sie erneut auf die letze Ecke klicken. Danach können Sie den Standort immer noch bearbeiten.",
        "usersViewingAccess": "Benutzer mit Lesezugriff",
        "usersEditingAccess": "Benutzer mit Schreibzugriff",
        "rights": "Rechte"
    },
    "fr": {
        "name": "Nom",
        "description": "Description",
        "drawLocation": "Dessinez la zone souhaitée sur la carte pour créer un nouvel emplacement.",
        "confirmResetLocationPolygon": "Souhaitez-vous réellement jeter le dessin ?",
        "confirmOk": "Jeter",
        "resetLocationPolygon": "Jeter le dessin",
        "tolerance": "Tolérence du geofence (mètres)",
        "toleranceInfo": "Si vous utilisez des appareils de suivi, vous pouvez définir ici à quelle distance l'appareil peut être en dehors de l'emplacement. Utilisez des règles pour être informé en cas de déplacement de l'emplacement.",
        "notInteger": "Doit être un numéro entier",
        "notPositive": "Doit être positif",
        "endLocation": "Pour finir de dessiner, cliquez à nouveau sur le dernier coin. Après cela, vous pouvez toujours modifier l'emplacement.",
        "usersViewingAccess": "Utilisateurs ayant accès à la lecture",
        "usersEditingAccess": "Utilisateurs ayant accès à l'écriture",
        "rights": "Droits"
    },
    "it": {
        "name": "Nome",
        "description": "Descrizione",
        "drawLocation": "Desegna l'arena desiderata sulla mappa per creare una nuova locazione.",
        "confirmResetLocationPolygon": "Eliminare il disegno?",
        "confirmOk": "Eliminare",
        "resetLocationPolygon": "Eliminare il disegno",
        "tolerance": "Tolleranza del geofence (metri)",
        "toleranceInfo": "Se stai usando dei dispositivi di tracciamento, puoi impostare qui quanto lontano dalla posizione è permesso al dispositivo di essere. Usa le regole per essere informato sull'uscita dal luogo.",
        "notInteger": "Deve essere un numero",
        "notPositive": "Deve essere positivo",
        "endLocation": "Um das Zeichnen zu beenden, können sie erneut auf die letze Ecke klicken. Danach können Sie den Standort immer noch bearbeiten.",
        "usersViewingAccess": "Utente con accesso in letturas",
        "usersEditingAccess": "Utente con accesso in scrittura",
        "rights": "Diritti"
    }
}
</i18n>
